<template>
	<div v-if="detail">
		<div class="img-bg-box">
			<img class="img-bg" :src="detail.img_id" alt="">
		</div>
		<div class="case-box">
			<div class="main-title">
				<div class="big-title">项目案例</div>
				<div class="small-title">— Project cases —</div>
			</div>
			<div class="content-cls">
				<div class="title-cls">{{detail.title}}</div>
				<div class="introduction-cls">{{detail.introduction}}</div>
				<div class="source-cls">来源：{{detail.source}}</div>
				<div class="rich-html" v-html="detail.detail"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				currentId: '', // 当前案例ID
				detail: null, // 当前案例详情
			}
		},
		created() {
			this.currentId = this.$route.params.id;
			this.getData();
		},
		methods: {
			// 获取数据
			getData() {
				this.$api.getCaseDetail({
					id: this.currentId
				}).then(res => {
					if (res.code == '0000') {
						this.detail = res.data;
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.case-box {
		padding: 90px 0 70px;

		.content-cls {
			padding-top: 70px;
			text-align: left;

			.title-cls {
				font-size: 18px;
				line-height: 24px;
				font-weight: bold;
				color: #333333;
			}

			.introduction-cls {
				padding: 20px 0;
				font-size: 14px;
				color: #F6776A;
				font-weight: 400;
				line-height: 24px;
				border-bottom: 1px solid #e6e6e6;
			}
			
			.source-cls{
				font-size: 12px;
				color: #666;
				margin-top: 20px;
			}
			
			.rich-html{
				margin-top: 20px;
				color: #333;
				font-size: 14px;
				line-height: 32px;
			}
			
			.rich-html{
				::v-deep img{
				   max-width: 100%;
				}
			}
		}
	}
</style>
